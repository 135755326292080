import React, { PureComponent } from 'react'
import Header from 'components/Header/Header'
import PropTypes from 'prop-types'
import { selectors as userSelectors } from 'store/modules/User'
import {
  actions as userProfileActions,
  selectors as userProfileSelectors,
} from 'store/modules/UserProfile'
import {
  actions as newsActions,
  selectors as newsSelectors,
} from 'store/modules/NewsFeed'
import { connect } from 'react-redux'
import {
  actions as headerActions,
  selectors as headerSelectors,
} from 'store/modules/Header'
import { bindActionCreators } from 'redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import {
  actions as submitFormActions,
  selectors as submitFormSelectors,
} from 'store/modules/WithSubmitForm'
import {
  selectors as aboutSelectors,
  actions as aboutActions,
} from 'store/modules/About'
import { injectOverlay } from 'shared-components/utils'
import FeedbackView from 'components/UserRatingPopup/FeedbackView'
import { FormattedMessage } from 'react-intl'
import '../../components/HowTo/HowTo.scss'
import {
  selectors as userNotificationSelectors,
  actions as userNotificationActions,
} from 'store/modules/UserNotification'

class HeaderContainer extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    toggleSearch: PropTypes.func,
    showSearch: PropTypes.bool,
    stickHeader: PropTypes.bool,
    loggedIn: PropTypes.bool,
    permission: PropTypes.object,
    showFieldsForUploading: PropTypes.func,
    fetchCookiePolicyAcceptance: PropTypes.func,
    loadUserProfile: PropTypes.func,
    userProfile: PropTypes.object,
    isFeedbackSuccess: PropTypes.bool,
    isError: PropTypes.object,
    postUserFeedback: PropTypes.func,
    openSnackBar: PropTypes.func,
    resetSuccessStatus: PropTypes.func,
    getAllUserFeedback: PropTypes.func,
    getWelcomeVideo: PropTypes.func,
    getUserFeedback: PropTypes.func,
    close: PropTypes.func,
    getActiveNewsFeedCate: PropTypes.func,
    getApplicationVersion: PropTypes.func,
    newsFeedCategoryHeader: PropTypes.object,
    params: PropTypes.object,
    isAddedSuccessfully: PropTypes.bool,
    intl: PropTypes.object,
    additionalValue: PropTypes.object,
    openConsentDialogue: PropTypes.bool,
    openWelcomeVideoDialogue: PropTypes.number,
    openWelcomeVideoContentType: PropTypes.number,
    openModal: PropTypes.func,
    match: PropTypes.object,
    toggle: PropTypes.func,
    getUserNotificationCount: PropTypes.func,
  }
  componentDidMount() {
    const { getActiveNewsFeedCate, getApplicationVersion , getUserNotificationCount, user} = this.props
    let intervalId = null
    const userInfo = user?.toJS()
    const {role} = userInfo || ''
    getActiveNewsFeedCate()
    getApplicationVersion()
    if(role === 'Customer Admin'
      || role === 'Technical User'
      || role === 'Quality Manager'
      || role === 'External Customer Tech'){
        getUserNotificationCount()
         intervalId = setInterval(() => {
          this.fetchNotificationCount(intervalId)
        }, 300000)
      }
    this.setData()
    return () => clearInterval(intervalId)
  }

  setData = () => {
    const {
      loadUserProfile,
      user,
      getAllUserFeedback,
      getWelcomeVideo,
      getUserFeedback,
      permission,
      match
    } = this.props
    const userPermission = permission && permission.toJS()
    const profileId = user?.get('id')
    profileId && !match?.params?.userId && loadUserProfile(profileId, true)
    const isVideo = window.localStorage.getItem('isVideo')

    if (
      userPermission &&
      userPermission['feedback'] &&
      userPermission['feedback']['VIEW']
    ) {
      getAllUserFeedback()
    } else {
      profileId && getUserFeedback(profileId)
    }
    if (
      isVideo &&
      userPermission &&
      userPermission['loginvideo'] &&
      userPermission['loginvideo']['VIEW']) {
      getWelcomeVideo()
    }
  }

  fetchNotificationCount = async (intervalId) =>{
    const {getUserNotificationCount} = this.props
    try {
      await getUserNotificationCount()
    } catch (error) {
      clearInterval(intervalId)
    }
  }

  openFeedbackPopup = () => {
    const { openModal } = this.props
    openModal(<FeedbackView {...this.props} />, { title: <FormattedMessage id='next.USER_RATING_TITLE' /> })
  }
  playWelcomeVideos = async (fileId, contentType) => {
    fileId !== null && contentType !== null && this.openWelcomeVideoPopup(fileId)
  }
  openWelcomeVideoPopup = (fileId) => {
    const { openModal } = this.props
    openModal(<video controls autoPlay muted className='videoPlayer'
      src={window.location.origin + `/nextconnect/how-to/api/v1/video/stream/` + fileId} />, { wrapClassName: 'play-video-modal' })
  }
  componentDidUpdate(prevProps) {
    const {
      isAddedSuccessfully,
      openSnackBar,
      resetSuccessStatus,
      getUserFeedback,
      user,
      intl,
      openConsentDialogue,
      openWelcomeVideoDialogue,
      openWelcomeVideoContentType,
      additionalValue,
      newsFeedCategoryHeader,
      getActiveNewsFeedCate,
      loadUserProfile
    } = this.props
    if (user && user !== prevProps?.user) {
      const profileId = user?.get('id')
      profileId && loadUserProfile(profileId, true)
    }
    if (!newsFeedCategoryHeader && newsFeedCategoryHeader !== prevProps.newsFeedCategoryHeader) {
      getActiveNewsFeedCate()
    }
    if (
      prevProps.isAddedSuccessfully !== isAddedSuccessfully &&
      prevProps.additionalValue?.isFeedback !== additionalValue?.isFeedback &&
      additionalValue?.isFeedback
    ) {
      const userId = user?.get('id')
      resetSuccessStatus()
      userId && getUserFeedback(userId)
      openSnackBar(
        { message: intl.formatMessage({ id: 'next.USER_FEED_BACK_SUCESS' }), wrapClassname: 'feedback_success' },
        true,
        'slow'
      )
    }
    if (openConsentDialogue && prevProps.openConsentDialogue !== openConsentDialogue) {
      this.openFeedbackPopup()
    }
    if (openWelcomeVideoDialogue && prevProps.openWelcomeVideoDialogue !== openWelcomeVideoDialogue) {
      this.playWelcomeVideos(openWelcomeVideoDialogue, openWelcomeVideoContentType)
    }
  }
  render() {
    const {
      newsFeedCategoryHeader,
      userProfile,
      permission,
      ...restProps
    } = this.props
    return (
      <Header
        userProfile={userProfile && userProfile.toJS()}
        permission={permission && permission.toJS()}
        newsFeedCategory={newsFeedCategoryHeader?.toJS()}
        {...restProps}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: userSelectors.user(state),
    permission: userSelectors.getUserPrivilege(state),
    showSearch: headerSelectors.showSearch(state),
    stickHeader: headerSelectors.stickHeader(state),
    loggedIn: userSelectors.loggedIn(state),
    mbleprofilePopup: headerSelectors.mbleprofilePopup(state),
    userStatus: userSelectors?.getStatus(state),
    cookiePolicyAcceptance: headerSelectors.cookiePolicyAcceptance(state),
    userProfile: userProfileSelectors.getUserProfile(state),
    newsFeedCategoryHeader: newsSelectors.newsFeedCategoryHeader(state),
    isFeedbackSuccess: headerSelectors.isFeedbackSuccess(state),
    isError: headerSelectors.isError(state),
    isUserFbdkFetching: headerSelectors.isUserFbdkFetching(state),
    openConsentDialogue: headerSelectors.openConsentDialogue(state),
    openWelcomeVideoDialogue: headerSelectors.openWelcomeVideoDialogue(state),
    openWelcomeVideoContentType: headerSelectors.openWelcomeVideoContentType(state),
    userRating: headerSelectors.userRating(state),
    isRated: headerSelectors.isRated(state),
    isAddedSuccessfully: submitFormSelectors.isAddedSuccessfully(state),
    additionalValue: submitFormSelectors.additionalValue(state),
    version: aboutSelectors.version(state),
    fetching: aboutSelectors.fetching(state),
    fetchError: aboutSelectors.fetchError(state),
    userNotificationCount: userNotificationSelectors.notificationCount(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      toggleSearch: () => headerActions.toggleSearch(),
      // showFieldsForUploading: fileManagementActions.showFieldsForUploading,
      openProfile: headerActions.openProfileHeader,
      acceptCookiePolicy: headerActions.acceptCookiePolicy,
      fetchCookiePolicyAcceptance: headerActions.fetchCookiePolicyAcceptance,
      loadUserProfile: userProfileActions.loadUserProfile,
      postUserFeedback: headerActions.postUserFeedback,
      getAllUserFeedback: headerActions.getAllUserFeedback,
      getWelcomeVideo: headerActions.getWelcomeVideo,
      getUserFeedback: headerActions.getUserFeedback,
      getActiveNewsFeedCate: (dispatch) =>
        newsActions.getActiveNewsFeedCate(dispatch),
      resetSuccessStatus: submitFormActions.resetSuccessStatus,
      getApplicationVersion: aboutActions.getApplicationVersion,
      getUserNotificationCount: userNotificationActions.getUserNotificationCount,
      updateUserNotifcnOpened:userNotificationActions.updateUserNotifcnOpened
    },
    dispatch
  )
}

export default injectOverlay(
  injectIntl(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer))
  )
)
