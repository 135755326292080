import React from 'react'
import './UserNotification.scss'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getIcon } from 'utils/iconUtils'

const UserNotificationCard = (props) =>{
  const {subject, timestamp, showDetails, details, type, historyPage} = props
  const notificationType = {
    ALERT: 'alert_reminder',
    CONTRACT: 'contract_reminder',
    MASSCOMM: 'mass_comm',
    SERVICE: 'service_reminder',
  }
  const cardItemClass = `${details ?'card-sub' : 'full-card'}`
  const cardTitleClass = `card-details ${cardItemClass}`
  const timeStampClass= `subj-div ${cardItemClass} ${historyPage ? 'center-text' : ''}`
  const detailsElem = <span className='truncate-subtext center-text'> {details} </span>
  const subjectClass = `subject-span truncate-text ${historyPage && !showDetails ? 'center-text-detail' : ''}`
  return (
    <div className='card-section'>
        <div className={cardItemClass}>
          <img alt='notfication-icon' height={48} width={48} src={getIcon(notificationType[type?.toUpperCase()] || notificationType['ALERT'])} />
          <p className={subjectClass}>
            <span>{subject}</span>
           {showDetails && detailsElem}
          </p>
        </div>
        {!showDetails && <div className={cardTitleClass}>
          {detailsElem}
        </div>}
        <div className={timeStampClass}>
          <span className='timestamp-span'>{moment(timestamp).fromNow()}</span>
        </div>
    </div>
  )
}

UserNotificationCard.propTypes = {
  subject: PropTypes.string,
  type: PropTypes.string,
  timestamp: PropTypes.number,
  details: PropTypes.string,
  showDetails: PropTypes.boolean,
  historyPage: PropTypes.boolean
}

export default UserNotificationCard
